'use client'
import useWhisperTranscription from '@/hooks/useWhisperTranscription'
import { CircleNotch, Microphone, Stop } from '@phosphor-icons/react'
import type { useChat } from 'ai/react'
import { memo, useEffect } from 'react'
import ShortUniqueId from 'short-unique-id'
import { twMerge } from 'tailwind-merge'

const Dictation = memo(
  ({
    append,
    silenceDuration,
    disabled,
    listeningPaused,
    onSubmitted,
  }: {
    append: ReturnType<typeof useChat>['append']
    silenceDuration: number
    disabled: boolean
    listeningPaused: boolean
    onSubmitted?: () => void
  }) => {
    const { isListening, formatDuration, duration, isProcessing, toggleRecording } = useWhisperTranscription(
      (input: string) => {
        const { randomUUID } = new ShortUniqueId({ length: 10 })
        const uuid = randomUUID()
        append({
          id: uuid,
          content: input,
          role: 'user',
          createdAt: new Date(),
        })
      },
      listeningPaused,
      silenceDuration,
    )

    useEffect(() => {
      if (isProcessing) {
        onSubmitted?.()
      }
    }, [isProcessing])

    return (
      <button
        className={twMerge('flex items-center justify-center w-16 py-2.5', isListening && 'w-28')}
        onClick={toggleRecording}
        type="button"
        disabled={isProcessing || disabled}
      >
        {isListening ? (
          <>
            <Stop className="flex-shrink-0 h-6 w-6 text-red-500" />
            <span className="ml-2 text-sm text-red-500"> {formatDuration(duration)}</span>
          </>
        ) : listeningPaused || isProcessing ? (
          <CircleNotch className="h-5 w-5 text-gray-500 animate-spin" />
        ) : (
          <Microphone className="h-6 w-6 text-primary" />
        )}
      </button>
    )
  },
)

export default Dictation
