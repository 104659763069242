'use client'

import { Alert } from '@/components/Alert'
import { Bug } from '@phosphor-icons/react/dist/ssr'
import useChatReplicaError from './useChatReplicaError'

export default function ChatError() {
  const { error, resetError } = useChatReplicaError()
  if (!error) {
    return
  }

  const { friendlyError, displayError, sentryId, stack } = error
  const isDev = process.env.NODE_ENV === 'development'

  return (
    <Alert
      heading={friendlyError}
      actionBtn={{
        text: 'Dismiss',
        handleActionBtn: resetError,
      }}
    >
      <>
        <p>An error occurred, please try again later.</p>
        <pre>
          <Bug className="inline" /> {sentryId}
        </pre>
        <p>Send us your feedback if this problem continues to occur.</p>
        {isDev && (
          <div className="bg-neutral-200 border-l-3 border-neutral-600 p-4 mt-4 overflow-x-auto rounded-md">
            <p className="font-bold">Development ONLY debug information</p>
            <p>{displayError}</p>
            <pre className="overflow-x-auto">Stack: {stack}</pre>
          </div>
        )}
      </>
    </Alert>
  )
}
