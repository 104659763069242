import _ from 'lodash'
import { useEffect, useState, useTransition } from 'react'
import { split } from 'sentence-splitter'

export default function useMessageStreamingCallback(
  text: string,
  _isStreaming: boolean,
  callback: (text: string, isLast: boolean) => Promise<void>,
) {
  const [lastSentSentenceIndex, setLastSentSentenceIndex] = useState(0)
  const [isStreaming, setIsStreaming] = useState(_isStreaming)
  const [lockedSentences, setLockedSentences] = useState<string[]>([])
  const [waitingForCallback, startTransition] = useTransition()

  const _sentences = split(text)
    .filter((sentenceObj) => sentenceObj.type === 'Sentence')
    .map((sentenceObj) => sentenceObj.raw)

  useEffect(() => {
    if (waitingForCallback) return
    if (lockedSentences.join('') !== _sentences.join('')) {
      setLockedSentences(_sentences)
    }
  }, [waitingForCallback, _sentences, lockedSentences])

  useEffect(() => {
    const sentences = lockedSentences
    if (!isStreaming) {
      return
    }

    if (sentences.length > 2) {
      const newLastSentSentenceIndex = sentences.length - 2
      const newSentences = sentences.slice(lastSentSentenceIndex, newLastSentSentenceIndex)
      if (newSentences.length) {
        startTransition(async () => {
          try {
            await callback(newSentences.join(' '), false)
          } catch {}
        })
        setLastSentSentenceIndex(newLastSentSentenceIndex)
      }
    }
  }, [isStreaming, lastSentSentenceIndex, lockedSentences.length])

  useEffect(() => {
    if (waitingForCallback) {
      return
    }
    if (_isStreaming !== isStreaming) {
      setIsStreaming(_isStreaming)
      if (!_isStreaming) {
        async function cb() {
          // send rest of the sentences
          const newSentences = _sentences.slice(lastSentSentenceIndex)
          if (newSentences.length) {
            const chunks = _.chunk(newSentences, 5)
            for (const sentencesChunk of chunks) {
              await callback(sentencesChunk.join(' '), true)
              console.log('last part---')
            }
            setLastSentSentenceIndex(0)
          }
        }
        cb()
      }
    }
  }, [callback, _isStreaming, isStreaming, waitingForCallback])
}
